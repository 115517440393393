import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import AlarmTable from "../../components/AlarmTable";
import SelectionOptions from "../../components/SelectionOptions";
import { useIntl } from "react-intl";
import { ACTIVE, ESCALATED, IN_PROGRESS } from "../../utils/constants";
import FiltersAndOrder from "../../components/FiltersAndOrder";
import EscalateIssueDialog from "../../components/Dialogs/EscalateIssueDialog";
import {
  useAlarm,
  useDevice,
  BasicBreadcrumbs,
  BasicScreenHeader,
  useLoadingGif,
  exportToExcel,
  getRequiredDateFormat,
  useUser,
  TenantIdContext,
  DarkModeContext,
  formatTimeStamp,
  useLocation,
  useSnackbar,
} from "@datwyler/shared-components";

const filterDefaults = {
  sites: [],
  deviceTypes: [],
  levels: [],
  statuses: [ACTIVE, IN_PROGRESS],
  assignedTo: [],
  startDate: null,
  endDate: null,
};

const Alarm = () => {
  const { tenantId }: any = useContext(TenantIdContext);
  const {
    isFetchAlarmLoading,
    fetchAlarmData,
    fetchAlarms,
    updateAlarms,
    isUpdateAlarmLoading,
    assignAlarms,
    isAssignAlarmsLoading,
    exportAlarms,
    isExportAlarmsLoading,
    exportAlarmsData,
    escalationHistory,
    escalationHistoryLoading,
    escalationHistoryData,
    unassignAlarms,
    isUnassignAlarmsLoading,
  } = useAlarm();
  const { isFetchLoading, fetchDeviceData, fetchDevices } = useDevice();
  const { isFetchUsersLoading, fetchUsersData, fetchUsers } = useUser();
  const {
    isFetchLoading: isFetchLocationLoading,
    fetchLocationData,
    fetchLocations,
  } = useLocation();
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const [filters, setFilters] = useState(filterDefaults);
  const [order, setOrder] = useState("time");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [selected, setSelected] = useState([]);
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);
  const { enqueueSnackbar } = useSnackbar();
  const [showEscalateIssue, setShowEscalateIssue] = useState(false);

  useEffect(() => {
    setIsLoading(
      isFetchAlarmLoading ||
        isFetchLoading ||
        isUpdateAlarmLoading ||
        isFetchUsersLoading ||
        isFetchLocationLoading ||
        isExportAlarmsLoading ||
        isAssignAlarmsLoading ||
        isUnassignAlarmsLoading ||
        false
    );
  }, [
    isFetchAlarmLoading,
    isFetchLoading,
    isUpdateAlarmLoading,
    isFetchUsersLoading,
    isFetchLocationLoading,
    isAssignAlarmsLoading,
    isExportAlarmsLoading,
  ]);

  useEffect(() => {
    fetchLocations({
      variables: { tenantId: tenantId },
    });

    fetchDevices({
      variables: {
        tenantId: tenantId,
        page: 0,
        size: 99999, // Big number to get all data
      },
    });

    fetchUsers({
      variables: {
        tenantId: tenantId,
        page: 0,
        size: 99999, // Big number to get all data
      },
    });
  }, [tenantId]);

  useEffect(() => {
    if (fetchAlarmData?.alarms?.page) {
      setTotalRows(fetchAlarmData?.alarms?.page.totalElements || 0);
    }
  }, [fetchAlarmData]);

  useEffect(() => {
    refetchAlarms();
  }, [filters, order, page, rowsPerPage, tenantId]);

  useEffect(() => {
    if (exportAlarmsData?.alarms?.alarms) {
      exportToExcel(
        getExcelData(),
        intl.formatMessage({ id: "excel_filename_alarm" })
      );
    }
  }, [exportAlarmsData]);

  const getFilters = () => {
    const filtersToSend = [];

    if (filters.sites.length > 0) {
      let filterDevices = "";
      filters.sites.forEach((siteId) => {
        const devices = fetchDeviceData?.devices?.devices?.filter(
          (device) => device.site?.id === siteId
        );
        const deviceIds = devices.map((device) => device.id);
        deviceIds?.forEach((deviceId, index) => {
          if (index === 0)
            filterDevices = filterDevices + `deviceId:` + deviceId;
          else filterDevices = filterDevices + `|deviceId:` + deviceId;
        });
      });
      if (!filterDevices) {
        enqueueSnackbar(intl.formatMessage({ id: "no_devices_in_site" }), {
          variant: "warning",
        });
        return null;
      }
      filtersToSend.push(filterDevices);
    }

    if (filters.deviceTypes.length > 0) {
      let filterDevices = "";
      filters.deviceTypes.forEach((deviceType) => {
        const devices = fetchDeviceData?.devices?.devices?.filter(
          (device) => device.type === deviceType
        );
        const deviceIds = devices.map((device) => device.id);
        deviceIds?.forEach((deviceId, index) => {
          if (index === 0)
            filterDevices = filterDevices + `deviceId:` + deviceId;
          else filterDevices = filterDevices + `|deviceId:` + deviceId;
        });
      });
      if (!filterDevices) {
        enqueueSnackbar(intl.formatMessage({ id: "no_devices_of_type" }), {
          variant: "warning",
        });
        return null;
      }
      filtersToSend.push(filterDevices);
    }

    if (filters.statuses.length > 0) {
      let statuses = "";
      filters.statuses.forEach((status, index) => {
        if (index === 0) statuses = statuses + `status:` + status;
        else statuses = statuses + `|status:` + status;
      });
      filtersToSend.push(statuses);
    }

    if (filters.levels.length > 0) {
      let levels = "";
      filters.levels.forEach((level, index) => {
        if (index === 0) levels = levels + `severity:` + level;
        else levels = levels + `|severity:` + level;
      });
      filtersToSend.push(levels);
    }

    if (filters.assignedTo.length > 0) {
      let assignedTos = "";
      filters.assignedTo.forEach((assignedTo, index) => {
        if (index === 0) assignedTos = assignedTos + `assignedTo:` + assignedTo;
        else assignedTos = assignedTos + `|assignedTo:` + assignedTo;
      });
      filtersToSend.push(assignedTos);
    }

    if (filters.startDate && filters.endDate) {
      const startTime = new Date(formatTimeStamp(filters.startDate));
      const endTime = new Date(formatTimeStamp(filters.endDate));
      startTime.setHours(0);
      startTime.setMinutes(0);
      startTime.setSeconds(-1); // day before at 23:59:59
      endTime.setDate(endTime.getDate() + 1);
      endTime.setHours(0);
      endTime.setMinutes(0);
      endTime.setSeconds(0); // day after at 00:00:00
      filtersToSend.push(`time>${startTime}`);
      filtersToSend.push(`time<${endTime}`);
    }

    return filtersToSend;
  };

  const getSort = () => {
    let sort = "";

    if (order) {
      switch (order) {
        case "time":
          sort = `time,desc`;
          break;
        case "assignedTo":
          sort = `assignedTo,asc`;
          break;
        case "updatedOn":
          sort = `updatedOn,desc`;
          break;
        case "status":
          sort = `status,asc`;
          break;
        case "severity":
          sort = `severity,asc`;
          break;
        default:
          sort = `time,desc`;
      }
    }

    return sort;
  };

  const refetchAlarms = () => {
    const filtersToSend = getFilters();
    if (!filtersToSend) return;
    const sort = getSort();

    fetchAlarms({
      variables: {
        tenantId: tenantId,
        filter: filtersToSend,
        sort: [sort],
        page: page,
        size: rowsPerPage,
      },
    });
  };

  const getLowerComponent = () => {
    if (selected.length > 0) {
      return (
        <>
          <Divider sx={{ borderColor: colors.dividerColor }} />

          <SelectionOptions
            selected={selected}
            setSelected={setSelected}
            updateAlarms={updateAlarms}
            setShowEscalateIssue={setShowEscalateIssue}
          />
        </>
      );
    } else {
      return (
        <>
          <Divider sx={{ borderColor: colors.dividerColor }} />
          <FiltersAndOrder
            setFilters={setFilters}
            filters={filters}
            order={order}
            setOrder={setOrder}
            locations={fetchLocationData?.locations}
            devices={fetchDeviceData?.devices?.devices}
            users={fetchUsersData?.users?.users}
          />
        </>
      );
    }
  };

  const fetchDataForExcel = () => {
    const filtersToSend = getFilters();
    const sort = getSort();

    exportAlarms({
      variables: {
        tenantId: tenantId,
        filter: filtersToSend,
        sort: [sort],
        page: 0,
        size: 9999999,
      },
    });
  };

  const getExcelData = () => {
    const excelData = exportAlarmsData?.alarms?.alarms?.map((data) => {
      const row = {};

      row[intl.formatMessage({ id: "id" })] = data.id || "-";
      row[intl.formatMessage({ id: "device" })] = data.device?.name || "-";
      row[intl.formatMessage({ id: "alarm_tbl_col_header_site" })] =
        data.device?.site?.name || "-";
      row[intl.formatMessage({ id: "alarm_tbl_col_header_attribute" })] = data
        .values?.attribute_name
        ? intl.formatMessage({ id: data.values?.attribute_name })
        : "-";
      row[intl.formatMessage({ id: "alarm_tbl_col_header_description" })] =
        data.description || "-";
      row[intl.formatMessage({ id: "alarm_tbl_col_header_severity" })] =
        data.severity
          ? intl.formatMessage({ id: data.severity.toLowerCase() })
          : "-";
      row[intl.formatMessage({ id: "date" })] = data.time
        ? getRequiredDateFormat(data.time, "DD/MM/YY HH:mm")
        : "-";
      row[intl.formatMessage({ id: "status" })] = data.status
        ? intl.formatMessage({ id: data.status.toLowerCase() })
        : "-";
      (row[intl.formatMessage({ id: "alarm_tbl_col_header_assigned_to" })] =
        data.assignedTo
          ? data.assignedTo.firstName + " " + data.assignedTo.lastName
          : "-"),
        (row[intl.formatMessage({ id: "last_updated_by" })] = data.updatedBy
          ? data.updatedBy.firstName + " " + data.updatedBy.lastName
          : "-");
      row[intl.formatMessage({ id: "last_updated_time" })] = data.updatedOn
        ? getRequiredDateFormat(data.updatedOn, "DD/MM/YY HH:mm")
        : "-";

      return row;
    });
    return excelData;
  };

  const escalateAlarms = (alarmDescription) => {
    const selectedIds = selected.map((alarm) => alarm.id);
    const status = ESCALATED;
    updateAlarms({
      variables: {
        input: {
          alarmIds: selectedIds,
          status: status,
          ...(alarmDescription && { message: alarmDescription }),
        },
      },
    });
    setSelected([]);
    setShowEscalateIssue(false);
  };

  return (
    <Box sx={{ paddingTop: "76px", paddingLeft: "24px", paddingRight: "24px" }}>
      <LoadingGif />
      <BasicBreadcrumbs activePage={"Alarm"} />
      <BasicScreenHeader
        title={intl.formatMessage({ id: "title_alarm" })}
        exportButton={{
          label: intl.formatMessage({ id: "export" }),
          onClick: fetchDataForExcel,
        }}
        LowerComponent={getLowerComponent()}
      />
      <AlarmTable
        alarmData={fetchAlarmData?.alarms?.alarms}
        devices={fetchDeviceData?.devices?.devices}
        selected={selected}
        setSelected={setSelected}
        users={fetchUsersData?.users?.users}
        updateAlarms={updateAlarms}
        assignAlarms={assignAlarms}
        unassignAlarms={unassignAlarms}
        escalationHistory={escalationHistory}
        escalationHistoryLoading={escalationHistoryLoading}
        escalationHistoryData={escalationHistoryData}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalRows={totalRows}
        tenantId={tenantId}
      />
      <EscalateIssueDialog
        open={showEscalateIssue}
        onClose={setShowEscalateIssue}
        escalateAlarmsHandler={escalateAlarms}
      />
    </Box>
  );
};

export default Alarm;
