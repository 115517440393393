import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import {
  ACTIVE,
  RESOLVED,
  DEACTIVATED,
  IN_PROGRESS,
  ESCALATED,
} from "../../utils/constants";
import AssignedToDialog from "../Dialogs/AssignedToDialog";
import IssueEscalationLogs from "../Dialogs/IssueEscalationLogs";
import { useIntl } from "react-intl";
import {
  BasicDataTable,
  AlarmBullet,
  BasicPagination,
  getRequiredDateFormat,
  getDevicesBySiteId,
  AddIcon,
  DarkModeContext,
} from "@datwyler/shared-components";

const headers = [
  "alarm_tble_col_id",
  "device",
  "alarm_tbl_col_header_site",
  "alarm_tbl_col_header_attribute",
  "alarm_tbl_col_header_description",
  "alarm_tbl_col_header_severity",
  "date",
  "status",
  "alarm_tbl_col_header_assigned_to",
  "last_updated_by",
  "last_updated_time",
  "actions",
];

const columnConfigs = {
  alarm_tble_col_id: {
    colspan: 0.5,
    isCentralized: false,
  },
  checkbox: {
    colspan: 0.5,
    isCentralized: false,
  },
  device: {
    colspan: 1,
    isCentralized: false,
  },
  alarm_tbl_col_header_site: {
    colspan: 1,
    isCentralized: false,
  },
  alarm_tbl_col_header_attribute: {
    colspan: 1.25,
    isCentralized: false,
  },
  alarm_tbl_col_header_description: {
    colspan: 1,
    isCentralized: false,
  },
  alarm_tbl_col_header_severity: {
    colspan: 1,
    isCentralized: false,
  },
  date: {
    colspan: 1,
    isCentralized: false,
  },
  status: {
    colspan: 0.5,
    isCentralized: false,
  },
  alarm_tbl_col_header_assigned_to: {
    colspan: 1.25,
    isCentralized: true,
  },
  last_updated_by: {
    colspan: 1.25,
    isCentralized: false,
  },
  last_updated_time: {
    colspan: 1.25,
    isCentralized: false,
  },
  actions: {
    colspan: 0.5,
    isCentralized: true,
  },
};

const AlarmTable = (props) => {
  const {
    alarmData,
    devices = [],
    selected,
    setSelected,
    users,
    updateAlarms,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalRows,
    assignAlarms,
    escalationHistory,
    escalationHistoryLoading,
    escalationHistoryData,
    tenantId,
    unassignAlarms,
  } = props;
  const [tableData, setTableData] = useState([[]]);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);
  const [showEscalationLogs, setShowEscalationLogs] = useState(false);
  useEffect(() => {
    if (alarmData) {
      setTableData(getData());
    }
  }, [alarmData]);

  useEffect(() => {
    setTableData(getData());
  }, [page, rowsPerPage, selected]);

  useEffect(() => {
    if (devices.length > 0) setTableData(getData());
  }, [devices]);

  const isSelected = (alarm) => {
    if (!selected) return false;
    return !!selected.find((s) => s.id === alarm.id);
  };

  const getData = () => {
    const data = [];
    if (alarmData) {
      let temp = JSON.parse(JSON.stringify(alarmData));

      temp.forEach((alarm) => {
        data.push({
          isChecked: isSelected(alarm),
          alarm_tble_col_id: alarm.id?.toString(),
          device: alarm.device?.name,
          alarm_tbl_col_header_site: alarm.device?.site.name,
          alarm_tbl_col_header_attribute: alarm.values?.attribute_name,
          alarm_tbl_col_header_description: alarm.description,
          alarm_tbl_col_header_severity: (
            <>
              <AlarmBullet severity={alarm.severity} width={7} height={7} />
              <Typography
                sx={{
                  color: colors.dataTableFont,
                  fontFamily: "NotoSans-Regular",
                  fontSize: "14px",
                  letterSpacing: "0.02px",
                  lineHeight: "20px",
                  overflowWrap: "anywhere",
                }}
              >
                {intl.formatMessage({ id: alarm.severity.toLowerCase() })}
              </Typography>
            </>
          ),
          status: alarm.status
            ? intl.formatMessage({ id: alarm.status.toLowerCase() })
            : "-",
          alarm_tbl_col_header_assigned_to: renderAssignedTo(alarm),
          last_updated_by: alarm.updatedBy
            ? alarm.updatedBy.firstName + " " + alarm.updatedBy.lastName
            : "-",
          last_updated_time: alarm.updatedOn
            ? getRequiredDateFormat(alarm.updatedOn, "DD/MM/YY HH:mm")
            : "-",
          date: alarm.time
            ? getRequiredDateFormat(alarm.time, "DD/MM/YY HH:mm")
            : "-",
          additionalInfo: alarm,
          moreButtonMenuItems: getMoreButtonMenu(alarm),
        });
      });
    }

    return data;
  };

  const renderAssignedTo = (alarm) => {
    return (
      <IconButton
        sx={{
          borderRadius: "8px",
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        id={alarm.id}
      >
        {!alarm?.assignedTo && (
          <Box
            sx={{
              width: "20px",
              height: "20px",
              borderRadius: "10px",
              backgroundColor: colors.datwylerTeal,
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AddIcon width={"20px"} height={"20px"} />
          </Box>
        )}
        <Box
          sx={{
            display: "inline-flex",
            marginLeft: "6px",
          }}
        >
          <Typography
            sx={{
              color: alarm?.assignedTo
                ? colors.datwylerTeal
                : colors.dataTableFont,
              fontFamily: "NotoSans-Regular",
              fontSize: "14px",
              letterSpacing: 0,
              lineHeight: "20px",
            }}
          >
            {alarm?.assignedTo
              ? alarm.assignedTo.firstName + " " + alarm.assignedTo.lastName
              : intl.formatMessage({ id: "alarm_tbl_assign_to" })}
          </Typography>
        </Box>
      </IconButton>
    );
  };

  const handleOnCheck = (additionalInfo) => {
    const temp: any = [...tableData];
    temp.map((row) => {
      if (row.additionalInfo.id === additionalInfo.id) {
        row.isChecked = !row.isChecked;

        const tempSelected = [...selected];

        if (tempSelected.length < 1) {
          tempSelected.push(row.additionalInfo);
        } else {
          const index = tempSelected.findIndex(
            (s) => s.id === additionalInfo.id
          );
          if (index > -1 && !row.isChecked) {
            tempSelected.splice(index, 1);
          } else {
            tempSelected.push(row.additionalInfo);
          }
        }
        setSelected(tempSelected);
      }
    });
    setTableData(temp);
  };

  const handleCheckAll = (e) => {
    const temp = [...tableData];
    const tempSelected = [];
    temp.map((row: any) => {
      row.isChecked = e.target.checked;
      if (e.target.checked) {
        tempSelected.push(row.additionalInfo);
      }
    });

    setSelected(tempSelected);
    setTableData(temp);
  };

  const handleClickRow = (additionalInfo) => {
    //handleOnCheck(additionalInfo);
  };

  const getMoreButtonMenu = (alarm) => {
    const { status } = alarm;
    return [
      {
        label: intl.formatMessage({ id: "go_to_device" }),
        value: null,
        onClick: () => {
          goToDevice(alarm);
        },
      },
      {
        label: intl.formatMessage({ id: "in_progress" }),
        value: null,
        onClick: () => {
          handleUpdateStatus(alarm, IN_PROGRESS);
        },
        isDisabled: [DEACTIVATED, RESOLVED, IN_PROGRESS].some(
          (disableStatus) => disableStatus === status
        ),
      },
      {
        label: intl.formatMessage({ id: "deactivated" }),
        value: null,
        onClick: () => {
          handleUpdateStatus(alarm, DEACTIVATED);
        },
        isDisabled: [DEACTIVATED, ESCALATED, IN_PROGRESS, RESOLVED].some(
          (disableStatus) => disableStatus === status
        ),
        menuItemFontStyle: {
          color: colors.red800,
          fontFamily: "NotoSans-Regular",
          fontSize: "14px",
          letterSpacing: 0,
          lineHeight: "20px",
          "&:hover": {
            color: colors.red800,
          },
        },
      },
      {
        label: intl.formatMessage({ id: "resolved" }),
        value: null,
        onClick: () => {
          handleUpdateStatus(alarm, RESOLVED);
        },
        isDisabled: [DEACTIVATED, ACTIVE, ESCALATED, RESOLVED].some(
          (disableStatus) => disableStatus === status
        ),
      },
      {
        label: intl.formatMessage({ id: "escalation_logs" }),
        value: null,
        onClick: () => {
          setShowEscalationLogs(true);
          escalationHistory({
            variables: {
              tenantId: tenantId,
              alarmId: alarm.id,
            },
          });
        },
        isDisabled: false,
      },
      {
        label: intl.formatMessage({ id: "unassign_alarm" }),
        value: null,
        onClick: () => {
          handleUnassignAlarm(alarm);
        },
        isDisabled: !alarm.assignedTo,
      },
    ];
  };

  const handleUnassignAlarm = ({ id }) => {
    unassignAlarms({
      variables: {
        input: {
          alarmIds: [id],
        },
      },
    });
  };

  const goToDevice = (alarm) => {
    const device = alarm.device;
    if (device) {
      const devicesInSameSite = getDevicesBySiteId(devices, device?.site?.id);
      navigate("/device-monitoring/deviceDetails/", {
        state: {
          device: device,
          devices: devicesInSameSite,
        },
      });
    }
  };

  const handleUpdateStatus = (alarm, status) => {
    updateAlarms({
      variables: {
        input: {
          alarmIds: [alarm.id],
          status: status,
        },
      },
    });
  };

  return (
    <Box sx={{ marginTop: "25px" }}>
      <BasicDataTable
        headers={headers}
        data={tableData}
        handleCheckAll={handleCheckAll}
        handleOnCheck={handleOnCheck}
        handleClickRow={handleClickRow}
        columnConfigs={columnConfigs}
      />
      <BasicPagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalRows={totalRows}
      />
      <AssignedToDialog
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        alarmData={alarmData}
        users={users}
        assignAlarms={assignAlarms}
      />
      <IssueEscalationLogs
        open={showEscalationLogs}
        onClose={setShowEscalationLogs}
        escalationHistoryLoading={escalationHistoryLoading}
        escalationHistoryData={escalationHistoryData}
      />
    </Box>
  );
};

export default AlarmTable;
