import { useContext, useRef } from "react";
import { useIntl } from "react-intl";
import {
  DarkModeContext,
  CloseIcon24p,
  BasicTextFieldWithoutForm,
  BasicButton,
} from "@datwyler/shared-components";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
const EscalateIssueDialog = ({ open, onClose, escalateAlarmsHandler }) => {
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);
  const alarmDescriptionInputRef = useRef<any>();

  const onCloseHandler = () => {
    onClose(false);
  };

  const onEscalateAlarms = () => {
    const description =
      alarmDescriptionInputRef && alarmDescriptionInputRef.current
        ? alarmDescriptionInputRef.current.value
        : "";
    escalateAlarmsHandler(description);
  };

  return (
    <Dialog
      open={open}
      onClose={onCloseHandler}
      maxWidth="sm"
      PaperProps={{
        sx: { borderRadius: "8  px", backgroundColor: colors.cardBg },
      }}
    >
      <DialogTitle sx={{ paddingTop: "26px", paddingBottom: "16px" }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-SemiBold",
            fontSize: 16,
            color: colors.subTitle,
          }}
        >
          Escalate Issue
        </Typography>
        <IconButton
          onClick={onCloseHandler}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon24p fill={colors.iconColor} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ fontSize: 14, paddingTop: 1, width: 376 }}>
          <BasicTextFieldWithoutForm
            multiline
            rows={4}
            handleOnChange={() => {}}
            placeholder={
              "Please provide any additional details here (optional)."
            }
            inputRef={alarmDescriptionInputRef}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ marginRight: "16px" }}>
          <BasicButton onClick={onCloseHandler} variant={"outlined"}>
            {intl.formatMessage({ id: "cancel" })}
          </BasicButton>
        </Box>
        <Box sx={{ marginRight: "16px" }}>
          <BasicButton
            onClick={onEscalateAlarms}
            variant={"contained"}
            sx={{ backgroundColor: colors.datwylerTeal }}
          >
            Escalate
          </BasicButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EscalateIssueDialog;
