import { Route, Routes } from "react-router-dom";
import Alarm from "./containers/Alarm";

const Router = (props: any) => {
  return (
    <Routes>
      <Route path="/alarm" element={<Alarm />} />
    </Routes>
  );
};

export default Router;
