import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Filters from "../Filters";
import Order from "../Order";
import Chips from "../Filters/Chips";
import {
  ACTIVE,
  CRITICAL,
  DEACTIVATED,
  ESCALATED,
  WARNING,
  IN_PROGRESS,
  RESOLVED,
} from "../../utils/constants";
import { useIntl } from "react-intl";

const FiltersAndOrder = (props) => {
  const { setFilters, filters, order, setOrder, locations, devices, users } =
    props;
  const intl = useIntl();
  const [siteOptions, setSiteOptions] = useState([]);
  const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
  const [assignedToOptions, setAssignedToOptions] = useState([]);

  useEffect(() => {
    const deviceTypeOptions = [];
    const checkAddedDevice = [];
    let countDevice = 1;

    if (devices) {
      devices.forEach((device) => {
        if (!checkAddedDevice.includes(device?.type)) {
          deviceTypeOptions.push({
            priority: countDevice++,
            label: intl.formatMessage({ id: device.type }),
            value: device.type,
          });

          checkAddedDevice.push(device?.type);
        }
      });
    }

    setDeviceTypeOptions(deviceTypeOptions);
  }, [devices]);

  useEffect(() => {
    if (users) {
      const userOptions = users.map((user, index) => {
        return {
          priority: index,
          label: user.firstName + " " + user.lastName,
          value: user.id,
        };
      });

      setAssignedToOptions(userOptions);
    } else {
      setAssignedToOptions([]);
    }
  }, [users]);

  useEffect(() => {
    const siteOptions = [];

    if (locations) {
      locations.forEach((location) => {
        location.sites.forEach((site, index) => {
          siteOptions.push({
            priority: index++,
            label: site.name,
            value: site.id,
          });
        });
      });
    }

    setSiteOptions(siteOptions);
  }, [locations]);

  const levelOptions = [
    // {
    //   priority: 0,
    //   label: intl.formatMessage({ id: INFORMATION.toLowerCase() }),
    //   value: INFORMATION,
    // },
    {
      priority: 1,
      label: intl.formatMessage({ id: WARNING.toLowerCase() }),
      value: WARNING,
    },
    {
      priority: 2,
      label: intl.formatMessage({ id: CRITICAL.toLowerCase() }),
      value: CRITICAL,
    },
  ];

  const statusOptions = [
    {
      priority: 0,
      label: intl.formatMessage({ id: ACTIVE.toLowerCase() }),
      value: ACTIVE,
    },

    {
      priority: 1,
      label: intl.formatMessage({ id: ESCALATED.toLowerCase() }),
      value: ESCALATED,
    },
    {
      priority: 2,
      label: intl.formatMessage({ id: IN_PROGRESS.toLowerCase() }),
      value: IN_PROGRESS,
    },
    {
      priority: 3,
      label: intl.formatMessage({ id: RESOLVED.toLowerCase() }),
      value: RESOLVED,
    },
    {
      priority: 4,
      label: intl.formatMessage({ id: DEACTIVATED.toLowerCase() }),
      value: DEACTIVATED,
    },
  ];

  const isFiltersEmpty = () => {
    if (
      filters.sites.length < 1 &&
      filters.deviceTypes.length < 1 &&
      filters.levels.length < 1 &&
      filters.statuses.length < 1 &&
      filters.assignedTo.length < 1 &&
      !filters.startDate &&
      !filters.endDate
    )
      return true;

    return false;
  };

  const getLabel = (key, value) => {
    // reverse find the label via the values
    switch (key) {
      case "levels":
        const level = levelOptions.find((option) => option.value === value);
        if (level) return level.label;
      case "sites":
        const site = siteOptions.find((option) => option.value === value);
        if (site) return site.label;
      case "deviceTypes":
        const deviceType = deviceTypeOptions.find(
          (option) => option.value === value
        );
        if (deviceType) return deviceType.label;
      case "statuses":
        const status = statusOptions.find((option) => option.value === value);
        if (status) return status.label;
      case "assignedTo":
        const assignedTo = assignedToOptions.find(
          (option) => option.value === value
        );
        if (assignedTo) return assignedTo.label;
    }

    return "";
  };

  return (
    <>
      <Box>
        <Box sx={{ display: "inline-flex" }}>
          <Filters
            setFilters={setFilters}
            siteOptions={siteOptions}
            deviceTypeOptions={deviceTypeOptions}
            assignedToOptions={assignedToOptions}
            levelOptions={levelOptions}
            statusOptions={statusOptions}
            filterDefaults={filters}
          />
        </Box>
        <Box
          sx={{
            display: "inline-flex",
            verticalAlign: "top",
            paddingTop: "16px",
          }}
        >
          <Order order={order} setOrder={setOrder} />
        </Box>
      </Box>
      {!isFiltersEmpty() && <Chips filters={filters} getLabel={getLabel} />}
    </>
  );
};

export default FiltersAndOrder;
