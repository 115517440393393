import { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import {
  DEACTIVATED,
  ESCALATED,
  IN_PROGRESS,
  RESOLVED,
} from "../../utils/constants";
import {
  DarkModeContext,
  BasicButton,
  useSnackbar,
} from "@datwyler/shared-components";

const SelectionOptions = (props) => {
  const { selected, setSelected, updateAlarms, setShowEscalateIssue } = props;
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { colors }: any = useContext(DarkModeContext);
  const [buttonDisableProps, setButtonDisableProps] = useState({
    isDeactivateDisabled: false,
    isEscalatedDisabled: false,
  });
  useEffect(() => {
    let { isDeactivateDisabled, isEscalatedDisabled } = buttonDisableProps;
    selected.forEach((element) => {
      isDeactivateDisabled = [
        DEACTIVATED,
        ESCALATED,
        IN_PROGRESS,
        RESOLVED,
      ].some((disableStatus) => disableStatus === element.status);
      isEscalatedDisabled = [DEACTIVATED, ESCALATED, RESOLVED].some(
        (disableStatus) => disableStatus === element.status
      );
    });

    setButtonDisableProps({
      isDeactivateDisabled: isDeactivateDisabled,
      isEscalatedDisabled: isEscalatedDisabled,
    });
  }, [selected.length]);

  const handleDeactivate = async () => {
    await Promise.all(
      selected.map((alarm) => {
        return updateAlarms({
          variables: {
            input: {
              alarmIds: [alarm.id],
              status: DEACTIVATED,
            },
          },
        });
      })
    ).then((allResponses) => {
      enqueueSnackbar(intl.formatMessage({ id: "alarms_deactivated" }), {
        variant: "info",
      });
      setSelected([]);
    });
  };

  return (
    <>
      <Box
        gap={2}
        sx={{
          paddingTop: "16px",
          display: "flex",
          alignItems: "center",
          marginLeft: "24px",
        }}
      >
        <BasicButton
          onClick={handleDeactivate}
          variant={"outlined"}
          sx={{
            border: "1px solid " + colors.blueGray300,
            height: "44px",
          }}
          disabled={buttonDisableProps.isDeactivateDisabled}
        >
          <Typography
            sx={{
              color: colors.red800,
              fontFamily: "NotoSans-Medium",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            {intl.formatMessage({ id: "deactivate" })}
          </Typography>
        </BasicButton>
        <BasicButton
          sx={{
            maxWidth: "164px",
            height: "44px",
          }}
          onClick={() => setShowEscalateIssue(true)}
          disabled={buttonDisableProps.isEscalatedDisabled}
        >
          {intl.formatMessage({ id: "issue_escalation" })}
        </BasicButton>
      </Box>
    </>
  );
};

export default SelectionOptions;
