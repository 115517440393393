import { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import {
  DarkModeContext,
  CloseIcon24p,
  BasicButton,
  BasicDataTable,
  useLoadingGif,
  getRequiredDateFormat,
} from "@datwyler/shared-components";

const headers = [
  "escalated_id",
  "escalated_message",
  "escalated_by",
  "escalated_on",
];

const columnConfigs = {
  escalated_id: {
    colspan: 1,
    isCentralized: true,
  },
  escalated_message: {
    colspan: 4,
    isCentralized: true,
  },
  escalated_by: {
    colspan: 4,
    isCentralized: true,
  },
  escalated_on: {
    colspan: 3,
    isCentralized: true,
  },
};

const IssueEscalationLogs = ({
  open,
  onClose,
  escalationHistoryLoading,
  escalationHistoryData,
}) => {
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);
  const { LoadingGif, setIsLoading } = useLoadingGif();

  useEffect(() => {
    setIsLoading(escalationHistoryLoading);
  }, [escalationHistoryLoading]);

  const data = escalationHistoryData
    ? (escalationHistoryData.alarmEscalationLogs || []).map((item) => ({
        escalated_id: item.id,
        escalated_message: item.message || "-",
        escalated_by: `${item.escalatedBy.firstName} ${item.escalatedBy.lastName}`,
        escalated_on: getRequiredDateFormat(item.escalatedOn, "DD/MM/YY HH:mm"),
      }))
    : [];

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={() => onClose(false)}
      PaperProps={{
        sx: { borderRadius: "8px", backgroundColor: colors.cardBg },
      }}
    >
      <DialogTitle sx={{ paddingTop: "26px", paddingBottom: "16px" }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-SemiBold",
            fontSize: 16,
            color: colors.subTitle,
          }}
        >
          {intl.formatMessage({ id: "escalation_logs" })}
        </Typography>
        <IconButton
          onClick={() => onClose(false)}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon24p fill={colors.iconColor} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "0px 24px" }}>
        <LoadingGif />
        <BasicDataTable
          headers={headers}
          data={data}
          columnConfigs={columnConfigs}
          isHideCheckbox={true}
          isHideActions={true}
        />
      </DialogContent>
      <DialogActions>
        <Box sx={{ marginRight: "16px" }}>
          <BasicButton
            onClick={() => {
              onClose(false);
            }}
            variant={"outlined"}
          >
            {intl.formatMessage({ id: "close" })}
          </BasicButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default IssueEscalationLogs;
