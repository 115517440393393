import { useState, MouseEvent, useContext } from "react";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import { useIntl } from "react-intl";
import {
  BasicSelectWithCheckBox,
  BasicButton,
  FilterIcon,
  DarkModeContext,
  BasicDateRangePickerWithoutForm,
  CalendarIcon,
  getEpochTime,
} from "@datwyler/shared-components";

const filterContainerStyle = {
  display: "inline-flex",
  height: "44px",
  marginLeft: "24px",
};

const Filters = (props) => {
  const {
    setFilters,
    siteOptions,
    deviceTypeOptions,
    assignedToOptions,
    levelOptions,
    statusOptions,
    filterDefaults,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  // added interimFilters as temporary fix while waiting for api integration
  // filters will be implemented in backend,
  // interimFilters act as a temp filter before user clicks apply
  // once backend ready, we can remove filters and use interimFilters to query the data
  // we can also rename interimFilters to filters as it's only used within this component
  const [interimFilters, setInterimFilters] = useState(filterDefaults);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChangeLevel = (levels: Array<string>) => {
    setInterimFilters({ ...interimFilters, levels: levels });
  };

  const handleChangeSite = (sites: Array<string>) => {
    setInterimFilters({ ...interimFilters, sites: sites });
  };

  const handleChangeDeviceTypes = (deviceTypes: Array<string>) => {
    setInterimFilters({ ...interimFilters, deviceTypes: deviceTypes });
  };

  const handleChangeStatus = (statuses: Array<string>) => {
    setInterimFilters({ ...interimFilters, statuses: statuses });
  };

  const handleChangeAssignedTo = (assignedTo: Array<string>) => {
    setInterimFilters({ ...interimFilters, assignedTo: assignedTo });
  };

  const handleChangeDate = (startDate, endDate) => {
    setInterimFilters({
      ...interimFilters,
      startDate: getEpochTime(startDate),
      endDate: getEpochTime(endDate),
    });
  };

  const applyFilters = () => {
    setFilters(interimFilters);
    handleClose();
  };

  const resetFilters = () => {
    setFilters(filterDefaults);
    setInterimFilters(filterDefaults);
  };

  return (
    <Box>
      <Box sx={{ paddingTop: "16px", display: "flex", alignItems: "center" }}>
        <Box sx={filterContainerStyle}>
          <BasicButton
            variant="outlined"
            onClick={handleClick}
            sx={{ border: "1px solid " + colors.blueGray300 }}
          >
            <FilterIcon fill={colors.iconColor} />
            <Typography
              sx={{
                fontFamily: "NotoSans-Medium",
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: 0,
                lineHeight: "20px",
                marginLeft: "10px",
              }}
            >
              {intl.formatMessage({ id: "filters" })}
            </Typography>
          </BasicButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              marginTop: "10px",
            }}
            PaperProps={{
              sx: {
                boxShadow: "0 2px 8px 0 rgba(16,24,40,0.16)",
                borderRadius: "8px",
                backgroundColor: colors.selectDropDownBg,
              },
            }}
          >
            <Box sx={{ padding: "16px" }}>
              <Box sx={{ width: "230px" }}>
                <BasicSelectWithCheckBox
                  onChange={handleChangeSite}
                  values={interimFilters.sites}
                  label={intl.formatMessage({ id: "sites" })}
                  menuItems={siteOptions}
                />
              </Box>
              <Box sx={{ width: "230px", marginTop: "16px" }}>
                <BasicSelectWithCheckBox
                  onChange={handleChangeDeviceTypes}
                  values={interimFilters.deviceTypes}
                  label={intl.formatMessage({ id: "device_types" })}
                  menuItems={deviceTypeOptions}
                />
              </Box>
              <Box sx={{ width: "230px", marginTop: "16px" }}>
                <BasicSelectWithCheckBox
                  onChange={handleChangeLevel}
                  values={interimFilters.levels}
                  label={intl.formatMessage({ id: "alarm_levels" })}
                  menuItems={levelOptions}
                />
              </Box>
              <Box sx={{ width: "230px", marginTop: "16px" }}>
                <BasicSelectWithCheckBox
                  onChange={handleChangeStatus}
                  values={interimFilters.statuses}
                  label={intl.formatMessage({ id: "status" })}
                  menuItems={statusOptions}
                />
              </Box>
              <Box sx={{ width: "230px", marginTop: "16px" }}>
                <BasicSelectWithCheckBox
                  onChange={handleChangeAssignedTo}
                  values={interimFilters.assignedTo}
                  label={intl.formatMessage({
                    id: "alarm_tbl_col_header_assigned_to",
                  })}
                  menuItems={assignedToOptions}
                />
              </Box>
              <Box sx={{ width: "230px", marginTop: "16px" }}>
                <BasicDateRangePickerWithoutForm
                  handleOnChange={handleChangeDate}
                  label={intl.formatMessage({ id: "date_range" })}
                  defaultStartDate={interimFilters.startDate}
                  defaultEndDate={interimFilters.endDate}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  popoverSx={{
                    marginTop: "-11px",
                  }}
                  InputProps={{
                    sx: {
                      width: "230px",
                      height: "44px",
                      fontSize: "14px",
                      letterSpacing: 0,
                      lineHeight: "20px",
                    },
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        component="div"
                        style={{ paddingLeft: "-14px" }}
                        disablePointerEvents
                      >
                        <CalendarIcon fill={colors.iconColor} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box
                sx={{ width: "230px", marginTop: "16px", textAlign: "right" }}
              >
                <BasicButton
                  variant={"outlined"}
                  onClick={resetFilters}
                  sx={{
                    padding: "12px 15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "NotoSans-Regular",
                      fontSize: "14px",
                      letterSpacing: 0,
                      lineHeight: "20px",
                    }}
                  >
                    {intl.formatMessage({ id: "reset" })}
                  </Typography>
                </BasicButton>
                <BasicButton
                  variant={"contained"}
                  onClick={applyFilters}
                  sx={{ marginLeft: "20px", padding: "12px 15px" }}
                >
                  {intl.formatMessage({ id: "apply" })}
                </BasicButton>
              </Box>
            </Box>
          </Popover>
        </Box>
      </Box>
    </Box>
  );
};

export default Filters;
